import { FC, Dispatch } from 'react';

import { CheckboxContainer, Link } from './styles';
import theme from 'styles/theme';

export interface CheckboxProps {
  text: string;
  stateKey: string;
  setState: Dispatch<any>;
  checked: boolean;
  error?: boolean;
  link?: {
    text: string;
    href: string;
  };
}

const Checkbox: FC<CheckboxProps> = ({
  setState,
  stateKey,
  text,
  checked,
  error,
  link,
}) => {
  const toggle = e => {
    setState(prev => ({ ...prev, [stateKey]: e.target.checked }));
  };

  const linkClick = link => {
    window.open(link);
    return;
  };

  return (
    <CheckboxContainer>
      <input
        type="checkbox"
        id={`checkbox-${stateKey}`}
        onChange={toggle}
        checked={checked}
      />
      <label htmlFor={`checkbox-${stateKey}`} className={error ? 'error' : ''}>
        {text}
        {` `}
        {link && <Link onClick={() => linkClick(link.href)}>{link.text}</Link>}
      </label>
    </CheckboxContainer>
  );
};

export default Checkbox;
