import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import {
  ClosePageButton,
  ClosePageIco,
  PageWrapper,
  TextBlock,
} from './styles';

import closeIco from 'assets/icons/close.svg';
import { Api } from 'utils/api';

const TermsAndConditions = () => {
  const { t } = useTranslation();

  const [terms, setTerms] = useState('');
  const [privacy, setPrivacy] = useState('');

  useEffect(() => {
    Api.getDealer().then(res => {
      if (res.terms) {
        setTerms(res.terms);
      }
      if (res.privacy) {
        setPrivacy(res.privacy);
      }
    });
  }, []);

  const closePage = () => {
    window.close();
  };

  return (
    <>
      <Helmet>
        <title>{t('terms_conditions_page.seo_title')}</title>
        <meta
          name="description"
          content={t('terms_conditions_page.seo_descriptions')}
        />
      </Helmet>
      <PageWrapper>
        <ClosePageButton aria-label="Close page" onClick={closePage}>
          <ClosePageIco src={closeIco} alt="close" />
        </ClosePageButton>
        <TextBlock>{parse(terms)}</TextBlock>
        <TextBlock>{parse(privacy)}</TextBlock>
      </PageWrapper>
    </>
  );
};

export default TermsAndConditions;
