import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Typography from 'app/components/Typography';
import BuyerInfo from 'app/components/BuyerInfo';
import Form from './Form';

import {
  PageWrapper,
  DetailsBlock,
  OrderInfo,
  ModuleTitle,
  ThanksBlock,
  ThanksText,
  ThanksContentBlock,
  CloseButton,
  BlockTitle,
  MainBlock,
  OrderInfoRow,
  OrderInfoRowPrice,
  OrderInfoTitle,
  TotalPriceBlock,
  ClosePageButton,
  ClosePageIco,
  BlockSubTitle,
  ThanksPopup,
  CheckBlock,
  DownloadButton,
} from './styles';

import checkedIco from 'assets/icons/checked.svg';
import closeIco from 'assets/icons/close.svg';
import errorIco from 'assets/icons/error.svg';
import pdf_file from 'assets/icons/pdf_file.svg';
import formatPrice from 'utils/formatPrice';
import { Api } from 'utils/api';
import { RootState } from 'types/RootState';
import { ICompanyCustomer, IPersonCustomer } from 'types/Order';

const OrderingPage = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { activeModule, price, base_price, config } = useSelector(
    (state: RootState) => state.module,
  );

  const [popupVisible, setPopupVisible] = useState(false);
  const [thanksVisible, setThanksVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [currencies, setCurrencies] = useState('');
  const [currencyRate, setCurrencyRate] = useState<string | null>(null);
  const [orderUUID, setOrderUUID] = useState<string>();

  useEffect(() => {
    if (!activeModule) {
      history.push('/');
    }
  }, [activeModule]);

  useEffect(() => {
    Api.getDealer().then(dealer => {
      setCurrencies(dealer.currency);
      setCurrencyRate(dealer.currency_rate);
    });
  }, []);

  const closePage = () => {
    history.push(`/configurator/${activeModule?.group_id}`);
  };

  const createOrder = (customer: IPersonCustomer | ICompanyCustomer) => {
    const set_product_variant_ids: number[] = [];
    for (const subgroup_id in config) {
      for (const product_id in config[subgroup_id]) {
        set_product_variant_ids.push(+config[subgroup_id][product_id].id);
      }
    }

    const body = {
      price: base_price,
      module_variant_id: activeModule!.id,
      set_product_variant_ids,
      customer,
    };

    Api.createOrder(body)
      .then(order => {
        setOrderUUID(order.uuid);
        setPopupVisible(true);
        setThanksVisible(true);
      })
      .catch(_ => {
        setErrorVisible(true);
      });
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  const closeThanks = () => {
    setThanksVisible(false);
  };

  const closeError = () => {
    setErrorVisible(false);
  };

  const download = () => {
    Api.downloadPdf(orderUUID!);
    console.log('download');
  };

  return (
    <>
      <Helmet>
        <title>{t('order_page.seo_title')}</title>
        <meta name="description" content={t('order_page.seo_description')} />
      </Helmet>
      <PageWrapper>
        <ClosePageButton aria-label="Close page" onClick={closePage}>
          <ClosePageIco src={closeIco} alt="close" />
        </ClosePageButton>
        {popupVisible && (
          <ThanksPopup>
            <BlockTitle style={{ margin: 0 }}>Thank you!</BlockTitle>
            <BlockSubTitle style={{ margin: 0 }}>
              {t('order_page.popup.text')}
            </BlockSubTitle>
            <CheckBlock>
              <img src={pdf_file} alt="pdf file" />
              {orderUUID && (
                <DownloadButton onClick={download}>
                  {t('order_page.popup.download')}
                </DownloadButton>
              )}
              <ClosePageButton aria-label="Close page" onClick={closePopup}>
                <ClosePageIco src={closeIco} alt="close" />
              </ClosePageButton>
            </CheckBlock>
          </ThanksPopup>
        )}
        {activeModule && <ModuleTitle>{activeModule.name}</ModuleTitle>}
        {thanksVisible && (
          <ThanksBlock>
            <ThanksContentBlock>
              <img src={checkedIco} alt="checked" />
              <ThanksText>{t('order_page.thx')}</ThanksText>
            </ThanksContentBlock>
            <CloseButton onClick={closeThanks}>
              <img src={closeIco} alt="close" />
            </CloseButton>
          </ThanksBlock>
        )}
        {errorVisible && (
          <ThanksBlock error>
            <ThanksContentBlock>
              <img src={errorIco} alt="error" />
              <ThanksText>{t('order_page.error_text')}</ThanksText>
            </ThanksContentBlock>
            <CloseButton onClick={closeError}>
              <img src={closeIco} alt="close" />
            </CloseButton>
          </ThanksBlock>
        )}
        <MainBlock>
          <DetailsBlock>
            <BlockTitle>{t('order_page.title_1')}</BlockTitle>
            <BlockSubTitle>{t('order_page.subtitle')}</BlockSubTitle>
            <Form createOrder={createOrder} />
            <BuyerInfo />
          </DetailsBlock>

          <OrderInfo>
            <BlockTitle>{t('order_page.title_2')}</BlockTitle>
            <OrderInfoRow>
              {activeModule && <Typography>{activeModule.name}</Typography>}
              <OrderInfoRowPrice>{`${currencies} ${formatPrice(
                base_price,
                currencyRate,
              )}`}</OrderInfoRowPrice>
            </OrderInfoRow>
            <OrderInfoRow>
              <Typography>{t('order_page.extras')}</Typography>

              <OrderInfoRowPrice>{`${currencies} ${formatPrice(
                price,
                currencyRate,
              )}`}</OrderInfoRowPrice>
            </OrderInfoRow>
            <TotalPriceBlock>
              <OrderInfoTitle>{t('order_page.total')}</OrderInfoTitle>

              <Typography>{`${formatPrice(
                price + base_price,
                currencyRate,
              )} ${currencies}`}</Typography>
            </TotalPriceBlock>
          </OrderInfo>
        </MainBlock>
      </PageWrapper>
    </>
  );
};

export default OrderingPage;
