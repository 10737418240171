import { combineReducers } from 'redux';

import moduleReducer from './module';

const rootReducer = combineReducers({
  module: moduleReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
