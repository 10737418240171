import { FC, useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  CookieConsentWrapper,
  CookieConsentMessage,
  CookieConsentContinueBtn,
} from './styles';

export interface CookieConsentProps {}

const CookieConsent: FC<CookieConsentProps> = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const cookies: any = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    if (cookies?.euCookiesAccepted !== '1') {
      setShow(true);
    }
  }, []);

  const onAcceptCookies = () => {
    saveCookieConsent();
    setShow(false);
  };

  const saveCookieConsent = () => {
    // We create date object and modify it to show date 10 years into the future.
    let expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    // Save the cookie with expiration date
    document.cookie =
      'euCookiesAccepted=1; path=/; expires=' + expirationDate.toUTCString();
  };

  const isServer = typeof window === 'undefined';
  if (isServer || !show) {
    return null;
  } else {
    return (
      <CookieConsentWrapper>
        <CookieConsentMessage>
          <Trans
            i18nKey="cookieConsent.message"
            values={{ cookieLink: t('cookieConsent.cookieLink') }}
            components={[
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="/terms-and-conditions"
              ></Link>,
            ]}
          />
        </CookieConsentMessage>
        <CookieConsentContinueBtn onClick={onAcceptCookies}>
          {t('cookieConsent.continue')}
        </CookieConsentContinueBtn>
      </CookieConsentWrapper>
    );
  }
};

export default CookieConsent;
