import styled from 'styled-components';
import theme from 'styles/theme';
import Typography from 'app/components/Typography';
import Button from 'app/components/Button';
import { h1, h3, caption } from 'app/components/Typography/styles';

export const PageWrapper = styled.div`
  position: relative;
  padding: 50px 81px 146px 149px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoints.l}) {
    padding: 50px;
  }
  @media (max-width: ${theme.breakpoints.md}) {
    align-items: center;
  }
`;

export const DetailsBlock = styled.div`
  width: 50%;
  margin-right: 30px;
  @media (max-width: ${theme.breakpoints.md}) {
    width: 100%;
    margin-right: 0;
  }
`;

export const OrderInfo = styled.div`
  width: 40%;
  @media (max-width: ${theme.breakpoints.md}) {
    width: 70%;
    margin-bottom: 70px;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    width: 100%;
  }
`;

export const ModuleTitle = styled.h1`
  font-weight: 400;
  font-size: 34px;
  color: #4a4a68;
  margin-bottom: 36px;
  @media (max-width: ${theme.breakpoints.md}) {
    margin-left: 0;
  }
`;

interface IThanksBlock {
  error?: boolean;
}

export const ThanksBlock = styled.div<IThanksBlock>`
  width: 50%;
  height: 48px;
  background-color: ${theme.colors.success_light};
  background-color: ${props =>
    props.error ? '#F8D3D3' : theme.colors.success_light};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px 0 16px;
  @media (max-width: ${theme.breakpoints.s}) {
    width: 100%;
  }
`;

export const ThanksContentBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const ThanksText = styled(Typography)`
  margin-left: 30px;
  font-family: ${theme.fontFamily.inter};
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  cursor: pointer;
`;

export const MainBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
  @media (max-width: ${theme.breakpoints.md}) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const BlockTitle = styled.h1`
  ${h1};
  margin-bottom: 42px;
`;

export const BlockSubTitle = styled.p`
  ${caption};
  color: ${theme.colors.primary_light};
  margin-top: -38px;
  margin-bottom: 25px;
`;

export const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > input {
    margin-top: 0;
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    flex-direction: column;
    & > input {
      width: 100% !important;
      &:not(&:first-child) {
        margin-top: 23px;
      }
    }
  }
`;

export const OrderButton = styled(Button)`
  color: ${theme.colors.white};
  background-color: ${theme.colors.primary};
  margin-top: 62px;
  font-family: ${theme.fontFamily.inter};
  font-weight: 600;
`;

export const OrderInfoRow = styled.div`
  padding: 0px 0px 13px 10px;
  border-bottom: 1px solid #dee5ef;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  &:not(&:first-child) {
    margin-top: 26px;
  }
`;

export const OrderInfoRowPrice = styled(Typography)`
  font-family: ${theme.fontFamily.inter};
`;

export const TotalPriceBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  margin-top: 38px;
  margin-bottom: 36px;
  @media (max-width: ${theme.breakpoints.s}) {
    align-items: center;
    padding-left: 0;
  }
`;

export const OrderInfoTitle = styled.h3`
  ${h3};
  margin-bottom: 10px;
`;

export const ClosePageButton = styled.button`
  background: none;
  position: absolute;
  top: 23px;
  right: 19px;
  cursor: pointer;
`;

export const ClosePageIco = styled.img`
  width: 24px;
  height: 24px;
`;

export const ToggleWrapper = styled.div`
  margin-bottom: 10px;
`;

export const ThanksPopup = styled.div`
  max-width: 363px;
  display: flex;
  flex-direction: column;
  padding: 46px 31px 53px 31px;
  border: 1px solid ${theme.colors.primary};

  position: absolute;
  top: 300px;
  left: 50%;
  transform: translateX(-50%);

  background: ${theme.colors.white};
  border-radius: 10px;
`;

export const CheckBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

export const DownloadButton = styled(Button)`
  color: ${theme.colors.white};
  background-color: ${theme.colors.primary};
  padding: 11px 55px;
`;
