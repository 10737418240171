import styled from 'styled-components';
import theme from 'styles/theme';

export const BuyerInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 58px;
`;

export const BuyerInfoRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${theme.breakpoints.md}) {
    &:not(&:last-child) {
      display: none;
    }
    flex-wrap: wrap;
    justify-content: space-around;
  }
`;

export const BuyerInfoBlock = styled.div`
  width: 70px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const BuyerInfoTitle = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  font-family: ${theme.fontFamily.roboto};
  text-align: center;
  color: ${theme.colors.primary};
  margin-bottom: 5px;
`;

export const BuyerInfoText = styled.span`
  font-size: 10px;
  font-weight: 400;
  line-height: 12.5px;
  font-family: ${theme.fontFamily.source_sans_pro};
  text-align: center;
  color: ${theme.colors.primary};
`;

export const BuyerInfoLine = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.primary};
  margin-top: 10px;
`;

export const BuyerInfoIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-top: 25px;
  margin-bottom: 10px;
  @media (max-width: ${theme.breakpoints.md}) {
    width: 20px;
    height: 20px;
  }
`;
