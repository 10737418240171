import { useState, useEffect } from 'react';
import { Link as ReactLink, withRouter, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  HeaderContainer,
  NavBlock,
  NavLink,
  Logout,
  NavButton,
  Line,
  MobileMenu,
  MobileMenuWrapper,
  MobileLink,
  Logo,
  LogoPlug,
} from './styles';

import useDisableBodyScroll from 'hooks/useDisableBodyScroll';

import logoutIco from 'assets/icons/logout.svg';
import { Api } from 'utils/api';

const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [mobMenuVisible, setMobMenuVisible] = useState(false);
  const [unmount, setUnmount] = useState(false);
  const [logo, setLogo] = useState<string>();

  useDisableBodyScroll(mobMenuVisible);

  useEffect(() => {
    Api.getDealer().then(dealer => {
      setLogo(dealer.logo);
    });
  }, []);

  const mobMenuToggle = () => {
    if (mobMenuVisible) {
      setUnmount(true);
      setTimeout(() => {
        setMobMenuVisible(false);
      }, 500);
    } else {
      setMobMenuVisible(true);
      setUnmount(false);
    }
  };

  return location.pathname !== '/order' &&
    location.pathname !== '/terms-and-conditions' ? (
    <HeaderContainer>
      {logo ? (
        <ReactLink to="/">
          <Logo src={logo} alt="Logo" />
        </ReactLink>
      ) : (
        <LogoPlug />
      )}
      <NavBlock>
        <NavLink to="/about" activeClassName="selected">
          {/* @ts-ignore */}
          {t('navigation.about')}
        </NavLink>
        <NavLink to="/contacts" activeClassName="selected">
          {t('navigation.contacts')}
        </NavLink>
        <a href="/admin" target="_blank">
          <Logout src={logoutIco} alt="Logout" />
        </a>
      </NavBlock>
      <NavButton onClick={mobMenuToggle}>
        <Line />
        <Line />
        <Line />
      </NavButton>
      {mobMenuVisible && (
        <MobileMenuWrapper>
          <MobileMenu
            className={unmount ? 'unmount' : ''}
            onClick={mobMenuToggle}
          >
            <MobileLink to="/about" activeClassName="selected">
              {t('navigation.about')}
            </MobileLink>
            <MobileLink to="/contacts" activeClassName="selected">
              {t('navigation.contacts')}
            </MobileLink>
            <a href="/admin" target="_blank">
              <Logout src={logoutIco} alt="Logout" />
            </a>
          </MobileMenu>
        </MobileMenuWrapper>
      )}
    </HeaderContainer>
  ) : null;
};

export default withRouter(Header);
