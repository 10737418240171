import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import {
  ImagesBlock,
  ImagesContainer,
  PageWrapper,
  Image,
  ImageText,
  TextBlock,
} from './styles';

import { Api } from 'utils/api';

import { IDealer } from 'types/Dealer';

const AboutUsPage = () => {
  const { t } = useTranslation();
  const [dealer, setDealer] = useState<IDealer>();

  useEffect(() => {
    Api.getDealer().then(res => {
      setDealer(res);
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>{t('about_page.seo_title')}</title>
        <meta name="description" content={t('about_page.seo_description')} />
      </Helmet>
      <PageWrapper>
        <ImagesContainer>
          {dealer?.dealer_employees.map(employees => (
            <ImagesBlock key={employees.name}>
              <Image src={employees.image} alt="avatar" />
              <ImageText>{employees.name}</ImageText>
            </ImagesBlock>
          ))}
        </ImagesContainer>
        {dealer?.about_us && <TextBlock>{parse(dealer.about_us)}</TextBlock>}
      </PageWrapper>
    </>
  );
};

export default AboutUsPage;
