import { FC, Dispatch, useEffect } from 'react';
import useProgressiveImg from 'hooks/useProgressiveImg';
import { StyledImage } from './styles';
interface BlurredUpImageProps {
  lowQualitySrc?: string;
  highQualitySrc?: string;
  className?: string;
  alt?: string;
  image_blend?: boolean;
  setDone?: Dispatch<boolean>;
}

const BlurredUpImage: FC<BlurredUpImageProps> = ({
  lowQualitySrc,
  highQualitySrc,
  className,
  alt,
  image_blend,
  setDone,
}) => {
  const [src, { blur }] = useProgressiveImg(lowQualitySrc, highQualitySrc);

  useEffect(() => {
    if (setDone) {
      setDone(src === highQualitySrc);
    }
  }, [src]);

  return (
    <StyledImage
      src={src}
      blur={false}
      className={className ? className : ''}
      alt={alt}
      image_blend={image_blend}
    />
  );
};

export default BlurredUpImage;
