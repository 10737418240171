import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import TextInput from 'app/components/TextInput';
import Checkbox from 'app/components/Checkbox';

import {
  PageWrapper,
  Image,
  MainBlock,
  Title,
  SubTitle,
  InputsWrapper,
  SendButton,
  LegalInfoBlock,
  LegalInfoTitle,
  LegalInfoText,
  ThanksBlock,
  ThanksContentBlock,
  CloseButton,
  ThanksText,
} from './styles';

import isValid from 'utils/isValid';

import houseImg from 'assets/images/contacts_page_house.png';
import checkedIco from 'assets/icons/checked.svg';
import closeIco from 'assets/icons/close.svg';

const EMAIL_REGEXP = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/g;

const ContactsPage = () => {
  const { t } = useTranslation();

  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    note: '',
    ppAgree: false,
  });
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    ppAgree: false,
  });
  const [thanksVisible, setThanksVisible] = useState(false);

  const send = () => {
    if (validate()) {
      setThanksVisible(true);
      console.log(userData);
    }
  };

  const closeThanksPopup = () => {
    setThanksVisible(false);
  };

  const validate = () => {
    const validation = {
      firstName: false,
      lastName: false,
      email: false,
      ppAgree: false,
    };
    for (const key in userData) {
      if (key !== 'note') {
        if (key === 'email') {
          validation[key] = !EMAIL_REGEXP.test(userData[key]);
        } else if (key === 'ppAgree') {
          validation[key] = !userData[key];
        } else {
          validation[key] = userData[key].length <= 0;
        }
      }
    }
    setErrors(validation);
    return isValid(validation);
  };

  useEffect(() => {
    setErrors({
      firstName: false,
      lastName: false,
      email: false,
      ppAgree: false,
    });
  }, [userData]);

  return (
    <>
      <Helmet>
        <title>{t('contact_page.seo_title')}</title>
        <meta name="description" content={t('contact_page.seo_description')} />
      </Helmet>
      <PageWrapper>
        <MainBlock>
          {thanksVisible && (
            <ThanksBlock>
              <ThanksContentBlock>
                <img src={checkedIco} alt="checked" />
                <ThanksText>{t('contact_page.email_popup')}</ThanksText>
              </ThanksContentBlock>
              <CloseButton onClick={closeThanksPopup}>
                <img src={closeIco} alt="close" />
              </CloseButton>
            </ThanksBlock>
          )}
          <Title>{t('contact_page.title')}</Title>
          <SubTitle>{t('contact_page.subtitle')}</SubTitle>
          <InputsWrapper>
            <TextInput
              placeholder={t('form.first_name')}
              width="48%"
              stateKey="firstName"
              setState={setUserData}
              state={userData.firstName}
              error={errors.firstName}
            />
            <TextInput
              placeholder={t('form.last_name')}
              width="48%"
              stateKey="lastName"
              setState={setUserData}
              state={userData.lastName}
              error={errors.lastName}
            />
          </InputsWrapper>
          <TextInput
            placeholder={t('form.email')}
            type="email"
            stateKey="email"
            setState={setUserData}
            state={userData.email}
            error={errors.email}
          />
          <TextInput
            placeholder={t('form.note')}
            type="textarea"
            stateKey="note"
            setState={setUserData}
            state={userData.note}
          />
          <Checkbox
            text={t('form.checkbox')}
            link={{
              text: t('form.checkbox_link'),
              href: '/terms-and-conditions',
            }}
            setState={setUserData}
            stateKey="ppAgree"
            checked={userData.ppAgree}
            error={errors.ppAgree}
          />
          <SendButton extended outlined={false} onClick={send}>
            {t('form.send')}
          </SendButton>
          <LegalInfoBlock>
            <LegalInfoTitle>{t('contact_page.info_title')}</LegalInfoTitle>
            <LegalInfoText>{t('contact_page.info_text')}</LegalInfoText>
          </LegalInfoBlock>
        </MainBlock>
        <Image src={houseImg} alt="house" />
      </PageWrapper>
    </>
  );
};

export default ContactsPage;
