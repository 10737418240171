import styled from 'styled-components';
import theme from 'styles/theme';
import { ButtonProps } from './index';

export const StyledButton = styled.button<ButtonProps>`
  padding: 11px 16px;
  border-radius: 4px;
  border: ${(props: ButtonProps) =>
    props.outlined
      ? `1px solid ${props.color ? props.color : theme.colors.primary_dark}`
      : 'none'};
  cursor: pointer;
  background: ${(props: ButtonProps) =>
    props.bgColor ? props.bgColor : 'none'};
  width: ${(props: ButtonProps) => (props.extended ? '100%' : 'initial')};

  font-size: ${theme.fonts.body};
  letter-spacing: ${theme.letterSpacing.body};
  font-family: ${theme.fontFamily.primary};
  font-weight: 400;
  color: ${(props: ButtonProps) =>
    props.color ? props.color : theme.colors.primary_dark};

  @media (min-width: ${theme.breakpoints.md}) {
    font-size: ${theme.fonts.body_md};
    letter-spacing: ${theme.letterSpacing.body_md};
  }

  @media (min-width: ${theme.breakpoints.l}) {
    font-size: ${theme.fonts.body_l};
    letter-spacing: ${theme.letterSpacing.body_l};
  }

  transition: 300ms ease;

  &:focus {
    outline: none;
  }

  &:disabled {
  }

  &:hover {
  }
`;
