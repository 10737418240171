import { FC } from 'react';

import { StyledLink } from './styles';

export interface LinkProps {
  children: string;
  to?: string;
  className?: string;
}

const Link: FC<LinkProps> = ({ children, to = '/', className }) => {
  return (
    <StyledLink to={to} className={className}>
      {children}
    </StyledLink>
  );
};

export default Link;
