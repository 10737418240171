const initialState = {
  config: {},
  price: 0,
  base_price: 0,
  activeModule: null,
  activePlanImage: null,
};

const moduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CONFIG':
      return {
        ...state,
        config: action.payload,
      };
    case 'CLEAR_CONFIG':
      return {
        ...state,
        config: {},
      };
    case 'ADD_CONFIG':
      const imageGroup = state.config[action.payload.id];
      if (imageGroup) {
        return {
          ...state,
          config: {
            ...state.config,
            [action.payload.id]: {
              ...imageGroup,
              ...action.payload.config,
            },
          },
        };
      }
      return {
        ...state,
        config: {
          ...state.config,
          [action.payload.id]: { ...action.payload.config },
        },
      };
    case 'SET_PRICE':
      return {
        ...state,
        price: action.payload,
      };
    case 'SET_BASE_PRICE':
      return {
        ...state,
        base_price: action.payload,
      };
    case 'RECALCULATE_PRICE':
      const config = state.config;
      let sum = 0;
      for (const x in config) {
        for (const y in config[x]) {
          if (config[x][y].price) {
            sum += config[x][y].price;
          }
        }
      }

      return {
        ...state,
        price: sum,
      };
    case 'SET_ACTIVE_MODULE':
      return {
        ...state,
        activeModule: action.payload,
      };
    case 'SET_ACTIVE_PLAN_IMAGE':
      return {
        ...state,
        activePlanImage: action.payload,
      };
    default:
      return state;
  }
};

export default moduleReducer;
