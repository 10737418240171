import { Dispatch, FC } from 'react';
import { useDispatch } from 'react-redux';

import { ToggleContainer, Input, Label, SecondaryLabel } from './styles';

import {
  setConfig,
  clearConfig,
  additionalAddConfig,
} from 'store/actions/module';
export interface ToggleButtonProps {
  text?: any;
  checked: boolean;
  setChecked: Dispatch<boolean>;
  secondaryText?: string;
}

const ToggleButton: FC<ToggleButtonProps> = ({
  text,
  checked,
  setChecked,
  secondaryText,
}) => {
  const dispatch = useDispatch();

  const toggle = e => {
    // dispatch(clearConfig());
    setChecked(e.target.checked);
  };

  return (
    <ToggleContainer>
      {secondaryText && (
        <SecondaryLabel htmlFor="toggle-button">{secondaryText}</SecondaryLabel>
      )}
      <Input
        type="checkbox"
        id="toggle-button"
        onChange={toggle}
        checked={checked}
      />
      {text && <Label htmlFor="toggle-button">{text}</Label>}
    </ToggleContainer>
  );
};

export default ToggleButton;
