import styled, { keyframes } from 'styled-components';
import theme from 'styles/theme';
import { h1, p } from 'app/components/Typography/styles';
import Button from 'app/components/Button';

export const PageWrapper = styled.main`
  height: calc(100vh - 80px);
  margin-top: 80px;
  display: flex;
  @media (max-width: ${theme.breakpoints.l}) {
    height: 100%;
    min-height: calc(100vh - 80px);
  }
`;

export const Image = styled.img`
  width: 50%;
  height: 100%;
  object-fit: cover;
  @media (max-width: ${theme.breakpoints.l}) {
    display: none;
  }
`;

export const MainBlock = styled.div`
  width: 50%;
  height: 100%;
  padding: 0 151px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: ${theme.breakpoints.xl}) {
    padding: 0 120px;
  }
  @media (max-width: ${theme.breakpoints.l}) {
    padding: 0 100px;
    width: 100%;
  }
  @media (max-width: ${theme.breakpoints.md}) {
    padding: 50px 100px;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    padding: 40px 30px;
  }
`;

export const Title = styled.h1`
  ${h1};
  margin-bottom: 15px;
`;

export const SubTitle = styled.p`
  ${p};
  text-align: center;
  margin-bottom: 66px;
  @media (max-width: ${theme.breakpoints.xl}) {
    margin-bottom: 30px;
  }
`;

export const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${theme.breakpoints.s}) {
    flex-direction: column;
    & > input {
      width: 100% !important;
    }
  }
`;

export const SendButton = styled(Button)`
  color: ${theme.colors.white};
  background-color: ${theme.colors.primary};
  margin-top: 20.5px;
`;

export const LegalInfoBlock = styled.div`
  width: 100%;
  margin-top: 53.5px;
  display: flex;
  flex-direction: column;
`;

export const LegalInfoTitle = styled.h3`
  font-weight: 700;
  font-size: 14px;
  color: ${theme.colors.primary_light};
  margin-bottom: 18px;
`;

export const LegalInfoText = styled.p`
  ${p};
  font-size: 13px;
  color: ${theme.colors.primary_light};
`;
const visible = keyframes`
  from {
    top: -45px;
    opacity: 0;
  }
  to {
    top: 15px;
    opacity: 1;
  }
`;

export const ThanksBlock = styled.div`
  position: absolute;
  top: 15px;
  width: 70%;
  height: 48px;
  background-color: ${theme.colors.success_light};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px 0 16px;
  animation: ${visible} 0.7s ease-in-out;
  @media (max-width: ${theme.breakpoints.s}) {
    width: 90%;
  }
`;

export const ThanksContentBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const ThanksText = styled.p`
  ${p};
  margin-left: 30px;
  font-family: ${theme.fontFamily.inter};
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  cursor: pointer;
`;
