import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  ImageBlock as ImageContainer,
  Image,
  HomeButton,
  BlurredProductImage,
} from './styles';
import houseIco from 'assets/icons/house.svg';
import { ChosenProduct, RootState } from 'types/RootState';
import { IModuleImageGroup } from 'types/Module';

interface ImageBlockProps {
  activeSubgroup?: number;
  image_groups?: IModuleImageGroup[];
}

interface IBaseImage {
  image: string;
  image_preview: string;
}

const ImageBlock: FC<ImageBlockProps> = ({ activeSubgroup, image_groups }) => {
  const history = useHistory();
  const { config } = useSelector((state: RootState) => state.module);

  const [baseImage, setBaseImage] = useState<IBaseImage>();
  const [products, setProducts] = useState<ChosenProduct>();
  const [baseImageDone, setBaseImageDone] = useState(false);

  useEffect(() => {
    const imageGroup = image_groups?.find(image_group => {
      return image_group.id === activeSubgroup;
    });
    if (imageGroup) {
      setBaseImage({
        image: imageGroup?.image,
        image_preview: imageGroup?.image_preview,
      });
    }
  }, [activeSubgroup, image_groups]);

  useEffect(() => {
    if (JSON.stringify(config) !== '{}' && activeSubgroup) {
      setProducts(config[activeSubgroup]);
    }
  }, [activeSubgroup, config]);

  const goHome = () => {
    history.push('/');
  };
  const prodsVariants: Array<any> = [];
  ((products && baseImageDone && Object.values(products)) || []).forEach(
    product => {
      if (!!product?.image && !!product?.image_preview) {
        prodsVariants.push(
          <BlurredProductImage
            highQualitySrc={product.image}
            lowQualitySrc={product.image_preview}
            image_blend={product.image_blend}
            key={product.id}
          />,
        );
      }
    },
  );
  return (
    <ImageContainer>
      {baseImage && (
        <Image
          highQualitySrc={baseImage.image}
          lowQualitySrc={baseImage.image_preview}
          setDone={setBaseImageDone}
        />
      )}
      {prodsVariants}

      <HomeButton onClick={goHome}>
        <img src={houseIco} alt="home" />
      </HomeButton>
    </ImageContainer>
  );
};

export default ImageBlock;
