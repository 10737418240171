import { CloseButton } from 'app/pages/OrderingPage/styles';
import React, { Fragment, FC } from 'react';
import {
  ModalBlock,
  ModalBody,
  ModalClose,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
} from './styles';

import closeIco from 'assets/icons/close.svg';

interface ModalProps {
  title?: string;
  footer?: string;
  children?: React.ReactNode;
  active: boolean;
  hideModal: () => void;
}

const Modal: FC<ModalProps> = ({
  title,
  footer,
  children,
  active,
  hideModal,
}) => {
  return (
    <Fragment>
      {active && (
        <ModalBlock>
          <ModalOverlay onClick={() => hideModal()}></ModalOverlay>
          <ModalContainer>
            <ModalHeader>
              <ModalTitle>{title}</ModalTitle>
              {/* <CloseButton onClick={() => hideModal()}>
                <img src={closeIco} alt="close" />
              </CloseButton> */}
              <ModalClose onClick={() => hideModal()}>
                <img src={closeIco} alt="close" />
              </ModalClose>
            </ModalHeader>
            <ModalBody>{children}</ModalBody>
            {footer && <ModalFooter>{footer}</ModalFooter>}
          </ModalContainer>
        </ModalBlock>
      )}
    </Fragment>
  );
};
export default Modal;
