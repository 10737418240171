import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useIsVisible } from 'react-is-visible';

import ToggleButton from 'app/components/ToggleButton';
import TotalPrice from 'app/components/TotalPrice';
import ConfiguratorHeader from 'app/components/ConfiguratorHeader';
import VariantsInput from 'app/components/VariantsInput';
import ImageBlock from './ImageBlock';

import {
  PageWrapper,
  RightBar,
  ScrollArea,
  Block,
  ProductTitle,
  VariantsBlock,
  GroupTitle,
  ScrollAreaEnd,
  ImageGroup,
} from './styles';

import {
  setBasePrice,
  setConfig,
  recalculatePrice,
  setActiveModule,
} from 'store/actions/module';
import { Api } from 'utils/api';
import filter, { IFilterModule, filterModules } from 'utils/new_filters';
import getDefaultVariant from 'utils/getDefaultVariant';
import useDebounce from 'hooks/useDebounce';

import { IModules } from 'types/Module';

const ConfiguratorPage = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const scrollAreaEndRef = useRef<HTMLDivElement>(null);
  const exteriorRef = useRef<HTMLDivElement>(null);
  const interiorRef = useRef<HTMLDivElement>(null);

  const isVisible = useIsVisible(scrollAreaEndRef);

  const buttonVisible = useDebounce(isVisible, 300);

  const [mirror, setMirror] = useState(false);
  const [modules, setModules] = useState<IModules>();
  const [products, setProducts] = useState<IFilterModule>();
  const [activeSubgroup, setActiveSubgroup] = useState<number>();
  const [currency, setCurrency] = useState('');
  const [currencyRate, setCurrencyRate] = useState<string | null>(null);

  useEffect(() => {
    Api.getModuleVariants(id)
      .then(modules => {
        if (modules.length === 0) {
          history.push('/404');
        }
        filterModules(modules, setModules);
      })
      .catch(_ => {
        history.push('/404');
      });

    Api.getDealer().then(dealer => {
      setCurrency(dealer.currency);
      setCurrencyRate(dealer.currency_rate);
    });
  }, []);

  useEffect(() => {
    if (products) {
      const exteriorConfig = getDefaultVariant(products.exterior);
      const interiorConfig = getDefaultVariant(products.interior);
      const config = { ...exteriorConfig, ...interiorConfig };

      dispatch(setConfig(config));
      dispatch(recalculatePrice());
    }
  }, [products]);

  useEffect(() => {
    if (modules) {
      dispatch(
        setBasePrice(
          mirror ? modules?.mirror?.base_price : modules?.standart.base_price,
        ),
      );
      dispatch(
        setActiveModule({
          id: mirror ? modules?.mirror?.id : modules?.standart?.id,
          name: mirror ? modules?.mirror?.name : modules?.standart?.name,
          group_id: +id,
        }),
      );
    }

    if (mirror && modules?.mirror) {
      filter(modules.mirror, setProducts);
    }
    if (!mirror && modules?.standart) {
      filter(modules.standart, setProducts);
    }
  }, [modules, mirror]);

  useEffect(() => {
    setActiveSubgroup(undefined);
  }, [mirror]);

  // useEffect(() => {
  //   if (buttonVisible) {
  //     //scrollAreaEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [buttonVisible]);

  return (
    <>
      <Helmet>
        {/* @ts-ignore */}
        <title>{t('configurator.seo_title')}</title>
        <meta name="description" content={t('configurator.seo_description')} />
      </Helmet>
      <PageWrapper>
        <ImageBlock
          image_groups={
            mirror
              ? modules?.mirror?.image_groups
              : modules?.standart.image_groups
          }
          activeSubgroup={activeSubgroup}
        />
        <RightBar>
          <ConfiguratorHeader
            name={mirror ? modules?.mirror?.name : modules?.standart.name}
            planImage={
              mirror
                ? modules?.mirror?.plan_image
                : modules?.standart.plan_image
            }
            virtualTourUrl={
              mirror
                ? modules?.mirror?.virtualtour_url
                : modules?.standart.virtualtour_url
            }
            setActiveSubgroup={id => setActiveSubgroup(id)}
            refs={[exteriorRef, interiorRef]}
            // exteriorDefaultImageGroupId={products?.exterior?.[0]?.id}
            // interiorDefaultImageGroupId={products?.interior?.[0]?.id}
          />
          <ScrollArea>
            {modules?.mirror && (
              <ToggleButton
                text={t('configurator.mirror')}
                checked={mirror}
                setChecked={setMirror}
              />
            )}

            <Block ref={exteriorRef} id="exterior">
              {products?.exterior && (
                <>
                  <GroupTitle>{t('configurator.exterior')}</GroupTitle>
                  {products.exterior.map((imageGroup, imageGroupIdx) => (
                    /* @ts-ignore */
                    <ImageGroup
                      key={imageGroup.id}
                      onClick={() => setActiveSubgroup(imageGroup.id)}
                    >
                      {!activeSubgroup &&
                        imageGroupIdx === 0 &&
                        setActiveSubgroup(imageGroup.id)}
                      {imageGroup.subgroups.map(subgroup => {
                        return (
                          subgroup.products.length > 0 && (
                            <VariantsBlock
                              key={`${subgroup.subgroup}_${subgroup.id}`}
                            >
                              <ProductTitle>{subgroup.subgroup}</ProductTitle>
                              {subgroup.products.map(product => (
                                <VariantsInput
                                  name={product.name}
                                  id={product.id}
                                  variants={product.variants}
                                  image_blend={
                                    product.image_merge_method === 'merge'
                                  }
                                  imageGroupId={imageGroup.id}
                                  key={`${subgroup.subgroup}_${product.id}`}
                                  currency={currency}
                                  currency_rate={currencyRate}
                                />
                              ))}
                            </VariantsBlock>
                          )
                        );
                      })}
                    </ImageGroup>
                  ))}
                </>
              )}
            </Block>

            <Block id="interior" ref={interiorRef}>
              {products?.interior && products.interior.length > 0 && (
                <>
                  <GroupTitle>{t('configurator.interior')}</GroupTitle>
                  {products.interior.map(imageGroup => (
                    <ImageGroup
                      key={imageGroup.id}
                      onClick={() => setActiveSubgroup(imageGroup.id)}
                    >
                      {imageGroup.subgroups.map(subgroup => {
                        return (
                          subgroup.products.length > 0 && (
                            <VariantsBlock
                              key={`${subgroup.subgroup}_${subgroup.id}`}
                            >
                              <ProductTitle>{subgroup.subgroup}</ProductTitle>
                              {subgroup.products.map(product => (
                                <VariantsInput
                                  name={product.name}
                                  id={product.id}
                                  variants={product.variants}
                                  image_blend={
                                    product.image_merge_method === 'merge'
                                  }
                                  imageGroupId={imageGroup.id}
                                  key={`${subgroup.subgroup}_${product.id}`}
                                  currency={currency}
                                  currency_rate={currencyRate}
                                />
                              ))}
                            </VariantsBlock>
                          )
                        );
                      })}
                    </ImageGroup>
                  ))}
                </>
              )}
            </Block>

            <ScrollAreaEnd
              ref={scrollAreaEndRef}
              buttonVisible={buttonVisible}
            />
          </ScrollArea>
          <TotalPrice
            buttonVisible={buttonVisible}
            currency={currency}
            currency_rate={currencyRate}
          />
        </RightBar>
      </PageWrapper>
    </>
  );
};

export default ConfiguratorPage;
