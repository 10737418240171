import { createGlobalStyle } from 'styled-components';
import reset from './reset';
import theme from './theme';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  * {
    vertical-align: baseline;
    font-family: inherit;
    font-style: inherit;
    font-size: 100%;
    border: none;
    padding: 0;
    margin: 0;
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    color: ${theme.colors.primary_dark};
    background-color: ${theme.colors.white};
    font-family: ${theme.fontFamily.primary};
    width: 100%;
  }

  ::selection {
    color: ${theme.colors.white};
    background-color: ${theme.colors.primary};
  }

   /* width */
   ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: ${theme.colors.white};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.primary_dark};
    transition: background 400ms ease;
    border-radius: 3px;
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${theme.colors.primary};
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
