import { Dispatch } from 'react';
import { IModulePart, IModuleSubgroup } from 'types/Module';
import { IModuleApi } from './api';

const getImageGroups = (module: IModuleApi) => {
  const image_groups = module.image_groups.map(img_group => {
    const subgroups = img_group.subgroup_ids.map(subgroup_id => {
      const subgroups = module.subgroups.find(
        subgroup => subgroup.id === subgroup_id,
      );
      return subgroups;
    });
    return {
      id: img_group.id,
      name: img_group.name,
      image: img_group.image,
      image_preview: img_group.image_preview,
      subgroups,
    };
  });

  return image_groups;
};

const sortProductVarinants = (product: IModulePart): IModulePart => {
  const variants = product.variants;

  const sort_variants = variants.sort((a, b) => {
    if (+a.position! < +b.position!) {
      return 1;
    } else if (+a.position! > +b.position!) {
      return -1;
    }
    return 0;
  });

  return {
    ...product,
    variants: sort_variants,
  };
};

const filterProducts = (module: IModuleApi) => {
  const group_products = module.products;
  const _image_groups = getImageGroups(module);

  const image_groups = _image_groups.map(image_group => {
    const subgroups = image_group.subgroups.map(subgroup => {
      const products = group_products.filter(group_product => {
        return group_product.subgroup.name === subgroup?.subgroup;
      });

      const _products = products.map(product => sortProductVarinants(product));

      return { ...subgroup, products: _products };
    });
    return { ...image_group, subgroups };
  });

  return image_groups;
};

const groupFilter = (
  group_name: 'exterior' | 'interior',
  module: IModuleApi,
) => {
  const image_groups = filterProducts(module);
  const group = image_groups.filter(image_group => {
    let result = true;

    image_group.subgroups.forEach(subgroup => {
      subgroup.products.forEach(product => {
        if (product.group !== group_name) {
          result = false;
        }
      });
    });

    return result;
  });
  return group;
};

const filterData = (module: IModuleApi, setProducts: Dispatch<any>) => {
  const exterior = groupFilter('exterior', module);
  const interior = groupFilter('interior', module);

  setProducts({
    exterior,
    interior,
  });
};

export const filterModules = (
  modules: IModuleApi[],
  setModules: Dispatch<any>,
) => {
  const _modules = modules.sort((a, b) =>
    a.position > b.position ? 1 : b.position > a.position ? -1 : 0,
  );

  setModules({
    standart: _modules[0],
    mirror: _modules[1],
  });
};

export default filterData;

export interface IFilterImageGroup {
  id: string;
  name: string;
  image: string;
  image_preview: string;
  subgroups: (IModuleSubgroup | undefined)[];
}

interface IFilterSubgroup extends IModuleSubgroup {
  products: IModulePart[];
}

export interface IFilterImageGroupU {
  id: number;
  name: string;
  image: string;
  image_preview: string;
  subgroups: IFilterSubgroup[];
}
export interface IFilterModule {
  exterior: IFilterImageGroupU[];
  interior: IFilterImageGroupU[];
}
