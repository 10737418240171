import styled, { keyframes } from 'styled-components';
import { NavLink as ReactNavLink } from 'react-router-dom';
import { Link } from 'app/components';
import { h1 } from 'app/components/Typography/styles';
import theme from 'styles/theme';

export const HeaderContainer = styled.header`
  width: 100%;
  padding: 16px 67px 0;
  background-color: ${theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 10;
  @media (max-width: ${theme.breakpoints.md}) {
    padding: 16px 40px;
  }
  @media (max-width: ${theme.breakpoints.md}) {
    padding: 16px 30px;
  }
`;

export const NavBlock = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${theme.breakpoints.s}) {
    display: none;
  }
`;

export const NavLink = styled(ReactNavLink)`
  color: ${theme.colors.primary};
  text-decoration: none;

  &:not(&:first-child) {
    margin-left: 50px;
  }
  &.selected {
    padding: 11px 16px;
    border: 1px solid ${theme.colors.primary};
    border-radius: 4px;
  }
`;

export const Logout = styled.img`
  margin-left: 50px;
  @media (max-width: ${theme.breakpoints.s}) {
    margin-left: 0;
  }
  width: 30px;
  height: 30px;
`;

export const NavButton = styled.button`
  width: 30px;
  height: 25px;
  background: none;
  flex-direction: column;
  justify-content: space-between;
  display: none;

  &:focus {
    outline: none;
  }

  @media (max-width: ${theme.breakpoints.s}) {
    display: flex;
  }
`;

export const Line = styled.span`
  width: 100%;
  height: 3px;
  background-color: ${theme.colors.primary};
`;

const visible = keyframes`
  from {
    opacity: 0;
    left: 100%;
  }
  to {
    opacity: 1;
    left: 0;
  }
`;

const hide = keyframes`
  from {
    opacity: 1;
    left: 0;
  }
  to {
    opacity: 0;
    left: 100%;
  }
`;

export const MobileMenu = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 15px 70px 15px;
  background-color: ${theme.colors.white};
  animation: ${visible} 0.5s ease-out;

  &.unmount {
    animation: ${hide} 0.5s ease-out;
  }
`;

export const MobileMenuWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100vh - 76px);
  top: 76px;
  left: 0;

  z-index: 5;
  overflow: hidden;
`;

export const MobileLink = styled(ReactNavLink)`
  ${h1};
  color: ${theme.colors.primary};
  margin-bottom: 50px;
  text-decoration: none;

  &.selected {
    border-bottom: 1px solid ${theme.colors.primary};
  }
`;

export const Logo = styled.img`
  height: 47px;
  width: auto;
`;

export const LogoPlug = styled.div`
  height: 100%;
  width: 0;
`;
