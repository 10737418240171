import styled from 'styled-components';
import theme from 'styles/theme';
import { p } from 'app/components/Typography/styles';

export const ToggleContainer = styled.div``;

export const Input = styled.input`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin: 0;
  vertical-align: top;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.success_light};
  border-radius: 48px;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    left: 3px;
    top: 0.5px;
    width: 16.5px;
    height: 16.5px;
    background-color: ${theme.colors.success};
    border-radius: 50%;

    transform: translateX(0);

    transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }

  &:checked::after {
    transform: translateX(100%);
    background-color: ${theme.colors.success};
  }
  &:checked {
    background-color: ${theme.colors.success_light};
  }
`;

export const Label = styled.label`
  ${p};
  color: ${theme.colors.primary_light};
  margin-left: 10px;
`;

export const SecondaryLabel = styled.label`
  ${p};
  color: ${theme.colors.primary_light};
  margin-right: 10px;
`;
