import { FC, Dispatch } from 'react';

import { StyledInput, StyledTextArea } from './styles';
import theme from 'styles/theme';

interface TextInputProps {
  placeholder: string;
  type?: 'email' | 'phone' | 'textarea';
  width?: string;
  stateKey: string;
  setState: Dispatch<any>;
  state: string;
  error?: boolean;
}

const TextInput: FC<TextInputProps> = ({
  placeholder,
  type,
  width,
  setState,
  stateKey,
  state,
  error,
}) => {
  const onChange = e => {
    setState(prev => ({ ...prev, [stateKey]: e.target.value }));
  };

  return type !== 'textarea' ? (
    <StyledInput
      placeholder={placeholder}
      type={type ? type : 'text'}
      style={{
        width: width,
        borderColor: error ? theme.colors.error : theme.colors.primary_light,
      }}
      onChange={onChange}
      value={state}
    />
  ) : (
    <StyledTextArea
      placeholder={placeholder}
      style={{ width: width }}
      onChange={onChange}
      value={state}
    />
  );
};

export default TextInput;
