import axios from 'axios';

export const setConfig = config => ({
  type: 'SET_CONFIG',
  payload: config,
});

export const clearConfig = () => ({
  type: 'CLEAR_CONFIG',
});

export const addConfig = config => ({
  type: 'ADD_CONFIG',
  payload: config,
});

export const setBasePrice = config => ({
  type: 'SET_BASE_PRICE',
  payload: config,
});

export const recalculatePrice = () => ({
  type: 'RECALCULATE_PRICE',
});

export const additionalAddConfig = (config, imageGroupId) => dispatch => {
  dispatch(addConfig({ config, id: imageGroupId }));
  dispatch(recalculatePrice());
};

export const setActiveModule = moduleInfo => ({
  type: 'SET_ACTIVE_MODULE',
  payload: moduleInfo,
});

export const setActivePlanImage = planImage => ({
  type: 'SET_ACTIVE_PLAN_IMAGE',
  payload: planImage,
});
