import styled from 'styled-components';
import theme from 'styles/theme';
import { h1, p, h2, h3 } from 'app/components/Typography/styles';

export const PageWrapper = styled.main`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 80px;
  @media (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
  }
`;

export const ImagesContainer = styled.div`
  width: 28%;
  min-height: calc(100vh - 80px);
  padding: 52px 0 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ebebeb;
  @media (max-width: ${theme.breakpoints.md}) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: initial;
    padding: 40px;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    flex-direction: column;
    padding: 40px 0;
  }
`;

export const TextBlock = styled.div`
  width: 72%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 71px 253px 100px 103px;
  @media (max-width: ${theme.breakpoints.l}) {
    padding: 71px 100px 100px 100px;
  }
  @media (max-width: ${theme.breakpoints.md}) {
    width: 100%;
    padding: 60px 80px 80px 80px;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    width: 100%;
    padding: 50px 30px;
  }

  & > h1 {
    ${h1};
    margin-bottom: 33px;
  }
  & > h2 {
    ${h2};
  }
  & > h3 {
    ${h3};
  }
  & > p {
    ${p};
    color: ${theme.colors.primary_light};
  }
  & > ul,
  & > ol {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    & > li {
      ${p};
      color: ${theme.colors.primary_light};
    }
  }
`;

export const ImagesBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  &:not(&:first-child) {
    margin-top: 35px;
  }
  @media (max-width: ${theme.breakpoints.md}) {
    &:not(&:first-child) {
      margin-top: 0;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    &:not(&:first-child) {
      margin-top: 40px;
    }
  }
`;

export const Image = styled.img`
  width: 153px;
  height: 153px;
  border-radius: 50%;
  margin-bottom: 18px;
  object-fit: cover;
  @media (max-width: ${theme.breakpoints.md}) {
    width: 130px;
    height: 130px;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    width: 153px;
    height: 153px;
  }
`;

export const ImageText = styled.span`
  font-size: 19px;
  font-weight: 700;
  font-family: ${theme.fontFamily.source_sans_pro};
  line-height: 1.5rem;
  text-align: center;
`;
