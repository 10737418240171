import styled, { css } from 'styled-components';
import Typography from 'app/components/Typography';
import theme from 'styles/theme';

export const VariantsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
  /* &:first-child {
    margin-top: 39px;
  } */
  &:first-of-type {
    margin-top: 39px;
  }
  /* &:last-child {
    margin-bottom: 63px;
  } */
`;

export const Title = styled(Typography)`
  color: ${theme.colors.primary_light};
  text-align: center;
`;

export const Value = styled(Typography)`
  text-align: center;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 8px 0;
  margin: 5px 0 10px 0;
`;

interface IRadioButton {
  material: string | null;
}

export const RadioButton = styled.div<IRadioButton>`
  width: 39px;
  height: 39px;
  display: flex;
  align-items: center;

  & input[type='radio'] {
    display: none;
  }

  & label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    margin-right: 0;
    user-select: none;
    width: 39px;
    height: 39px;
    margin: 0;
    border-radius: 50%;
  }

  & label:before {
    content: '';
    display: inline-block;
    width: 33px;
    height: 33px;
    background-color: #ebeef2;
    border: 3px solid ${theme.colors.white};
    border-radius: 50%;
    padding: 3px;
    position: absolute;
    left: 1px;
    bottom: 1px;
    background-image: url(${props => (props.material ? props.material : '')});
    background-size: 33px;
    background-repeat: no-repeat;
    background-position: center;
  }

  & input[type='radio']:checked + label {
    border: 2px solid ${theme.colors.success};
  }

  /* & label:hover:before {
    filter: brightness(120%);
  } */

  &:not(&:first-child) {
    margin-left: 10px;
  }
`;
