const isValid = (obj: Object): boolean => {
  let valid = true;
  for (const key in obj) {
    if (obj[key]) {
      valid = false;
    }
  }

  return valid;
};

export default isValid;
