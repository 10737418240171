import { useState, FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  VariantsContainer,
  Title,
  ButtonsBlock,
  RadioButton,
  Value,
} from './styles';

import { additionalAddConfig } from 'store/actions/module';

import { IModulePartVariant } from 'types/Module';

interface VariantsInputProps {
  name: string;
  id: number;
  variants: IModulePartVariant[];
  image_blend: boolean;
  imageGroupId: number;
  currency: string;
  currency_rate: string | null;
}

const VariantsInput: FC<VariantsInputProps> = ({
  variants,
  name,
  id,
  image_blend,
  imageGroupId,
  currency,
  currency_rate,
}) => {
  const dispatch = useDispatch();

  const defaultValue = () => {
    const _variants = variants.find(variant => variant.default);
    return {
      name: _variants ? _variants.name : variants[0].name,
      id: _variants ? _variants.id : variants[0].id,
      price: _variants ? _variants.price : variants[0].price,
      image: _variants ? _variants.image : variants[0].image,
      image_preview: _variants
        ? _variants.image_preview
        : variants[0].image_preview,
    };
  };

  const [value, setValue] = useState(defaultValue());

  const changeValue = (variant: IModulePartVariant) => {
    const newValue = {
      id: variant.id,
      name: variant.name,
      price: variant.price,
      image: variant.image,
      image_preview: variant.image_preview,
      image_blend,
    };
    dispatch(additionalAddConfig({ [id]: newValue }, imageGroupId));
    setValue(newValue);
  };

  return (
    <VariantsContainer>
      <Title>{name}</Title>
      <ButtonsBlock>
        {variants.map((variant, index) => (
          <RadioButton
            key={`${id}${variant.id}`}
            material={variant.selector_image}
          >
            {variants.find(variant => variant.default) ? (
              <input
                id={`radio-${id}${variant.id}`}
                type="radio"
                name={`variant-${id}`}
                value={variant.name}
                onChange={() => changeValue(variant)}
                defaultChecked={variant.default}
              />
            ) : (
              <input
                id={`radio-${id}${variant.id}`}
                type="radio"
                name={`variant-${id}`}
                value={variant.name}
                onChange={() => changeValue(variant)}
                defaultChecked={index === 0}
              />
            )}
            <label htmlFor={`radio-${id}${variant.id}`} />
          </RadioButton>
        ))}
      </ButtonsBlock>
      <Value>{`${value.name}, +${
        currency_rate ? value.price * +currency_rate : value.price
      }${currency}`}</Value>
    </VariantsContainer>
  );
};

export default VariantsInput;
