import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from 'styles/theme';

export const StyledLink = styled(Link)`
  font-size: ${theme.fonts.body};
  letter-spacing: ${theme.letterSpacing.body};
  font-family: ${theme.fontFamily.primary};
  font-weight: 400;
  color: ${theme.colors.primary_dark};
  text-decoration: none;

  @media (min-width: ${theme.breakpoints.md}) {
    font-size: ${theme.fonts.body_md};
    letter-spacing: ${theme.letterSpacing.body_md};
  }

  @media (min-width: ${theme.breakpoints.l}) {
    font-size: ${theme.fonts.body_l};
    letter-spacing: ${theme.letterSpacing.body_l};
  }
`;
