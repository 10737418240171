import { IFilterImageGroupU } from 'utils/new_filters';

const getDefaultVariant = (products?: IFilterImageGroupU[]) => {
  let config = {};
  products?.forEach(imageGroup => {
    let group = {};
    imageGroup.subgroups.forEach(subgroup => {
      subgroup.products.forEach(product => {
        const variants = product.variants;

        const defaultVariant = variants.find(varinat => varinat.default);

        group = {
          ...group,
          [product.id]: {
            id: defaultVariant ? defaultVariant.id : variants[0].id,
            name: defaultVariant ? defaultVariant.name : variants[0].name,
            price: defaultVariant ? defaultVariant.price : variants[0].price,
            image: defaultVariant ? defaultVariant.image : variants[0].image,
            image_preview: defaultVariant
              ? defaultVariant.image_preview
              : variants[0].image_preview,
            image_blend: product.image_merge_method === 'merge',
          },
        };
      });
    });

    if (JSON.stringify(group) !== '{}') {
      config = {
        ...config,
        [imageGroup.id]: group,
      };
    }
  });
  return config;
};

export default getDefaultVariant;
