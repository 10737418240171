import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PriceBlock, TextBlock, Title, Price, OrderButton } from './styles';

import { RootState } from 'types/RootState';

interface TotalPriceProps {
  buttonVisible: boolean;
  currency: string;
  currency_rate: string | null;
}

const TotalPrice: FC<TotalPriceProps> = ({
  buttonVisible,
  currency,
  currency_rate,
}) => {
  const { t } = useTranslation();

  const { price, base_price } = useSelector((state: RootState) => state.module);

  const formattingPrice = (): string => {
    const new_price =
      (price + base_price) * (currency_rate ? +currency_rate : 1);

    const formated_price = new_price
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return formated_price;
  };

  return (
    <PriceBlock>
      <TextBlock>
        <Title>{t('configurator.total')}</Title>
        <Price>{`${formattingPrice()} ${currency}`}</Price>
      </TextBlock>
      {buttonVisible && (
        <OrderButton to="/order" extended>
          {t('configurator.order_now')}
        </OrderButton>
      )}
    </PriceBlock>
  );
};

export default TotalPrice;
