import styled from 'styled-components';
import theme from 'styles/theme';

export const CheckboxContainer = styled.div`
  width: 100%;
  &:not(&:first-child) {
    margin-top: 15px;
  }

  input[type='checkbox'] {
    display: none;
  }

  label {
    font-size: 13px;
    color: ${theme.colors.primary_light};
    position: relative;
    margin: 0;
    margin-left: 29px;
    cursor: pointer;
    user-select: none;
    &.error {
      color: ${theme.colors.error};
    }
  }

  label::before {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 4px;
    background-color: #ebeef2;
    position: absolute;
    left: -29px;
    top: 2px;

    transition: all 0.2s;
  }

  input[type='checkbox']:checked + label::before {
    background-color: ${theme.colors.success};
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 3.5L3.5 6.5L9 1' stroke='white'/%3E%3C/svg%3E%0A");
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const Link = styled.span`
  text-decoration: underline;
  color: #445c88;
`;
