import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  BuyerInfoBlock,
  BuyerInfo as BuyerInfoContainer,
  BuyerInfoRow,
  BuyerInfoTitle,
  BuyerInfoText,
  BuyerInfoLine,
  BuyerInfoIcon,
} from './styles';

import add_layer from 'assets/icons/add-layer.svg';
import bill from 'assets/icons/bill.svg';
import scroll from 'assets/icons/scroll.svg';
import downKey from 'assets/icons/down-key.svg';
import shippingBox from 'assets/icons/shipping-box.svg';
import realEstate from 'assets/icons/real-estate.svg';
import arrowIco from 'assets/icons/arrow-right.svg';

const BuyerInfo = () => {
  const { t } = useTranslation();

  return (
    <BuyerInfoContainer>
      <BuyerInfoRow>
        <BuyerInfoBlock>
          <BuyerInfoTitle>{t('workflow.title_1')}</BuyerInfoTitle>
        </BuyerInfoBlock>
        <BuyerInfoBlock>
          <BuyerInfoTitle>{t('workflow.title_2')}</BuyerInfoTitle>
          <BuyerInfoText>{t('workflow.subtitle_1')}</BuyerInfoText>
        </BuyerInfoBlock>
        <BuyerInfoBlock>
          <BuyerInfoTitle>{t('workflow.title_3')}</BuyerInfoTitle>
          <BuyerInfoText>{t('workflow.subtitle_2')}</BuyerInfoText>
        </BuyerInfoBlock>
        <BuyerInfoBlock>
          <BuyerInfoTitle>{t('workflow.title_4')}</BuyerInfoTitle>
          <BuyerInfoText>{t('workflow.subtitle_3')}</BuyerInfoText>
        </BuyerInfoBlock>
        <BuyerInfoBlock>
          <BuyerInfoTitle>{t('workflow.title_5')}</BuyerInfoTitle>
          <BuyerInfoText>{t('workflow.subtitle_4')}</BuyerInfoText>
        </BuyerInfoBlock>
        <BuyerInfoBlock>
          <BuyerInfoTitle>{t('workflow.title_6')}</BuyerInfoTitle>
        </BuyerInfoBlock>
      </BuyerInfoRow>
      <BuyerInfoRow>
        <BuyerInfoBlock>
          <BuyerInfoLine />
        </BuyerInfoBlock>
        <BuyerInfoBlock>
          <BuyerInfoLine />
        </BuyerInfoBlock>
        <BuyerInfoBlock>
          <BuyerInfoLine />
        </BuyerInfoBlock>
        <BuyerInfoBlock>
          <BuyerInfoLine />
        </BuyerInfoBlock>
        <BuyerInfoBlock>
          <BuyerInfoLine />
        </BuyerInfoBlock>
        <BuyerInfoBlock>
          <BuyerInfoLine />
        </BuyerInfoBlock>
      </BuyerInfoRow>
      <BuyerInfoRow>
        <BuyerInfoBlock>
          <BuyerInfoIcon src={add_layer} alt="add layer" />
          <BuyerInfoText>{t('workflow.text_1')}</BuyerInfoText>
        </BuyerInfoBlock>
        <img src={arrowIco} alt="arrow" />
        <BuyerInfoBlock>
          <BuyerInfoIcon src={bill} alt="bill" />
          <BuyerInfoText>{t('workflow.text_2')}</BuyerInfoText>
        </BuyerInfoBlock>
        <img src={arrowIco} alt="arrow" />
        <BuyerInfoBlock>
          <BuyerInfoIcon src={scroll} alt="scroll" />
          <BuyerInfoText>{t('workflow.text_3')}</BuyerInfoText>
        </BuyerInfoBlock>
        <img src={arrowIco} alt="arrow" />
        <BuyerInfoBlock>
          <BuyerInfoIcon src={downKey} alt="down key" />
          <BuyerInfoText>{t('workflow.text_4')}</BuyerInfoText>
        </BuyerInfoBlock>
        <img src={arrowIco} alt="arrow" />
        <BuyerInfoBlock>
          <BuyerInfoIcon src={shippingBox} alt="shipping box" />
          <BuyerInfoText>{t('workflow.text_5')}</BuyerInfoText>
        </BuyerInfoBlock>
        <img src={arrowIco} alt="arrow" />
        <BuyerInfoBlock>
          <BuyerInfoIcon src={realEstate} alt="real estate" />
          <BuyerInfoText>{t('workflow.text_6')}</BuyerInfoText>
        </BuyerInfoBlock>
      </BuyerInfoRow>
    </BuyerInfoContainer>
  );
};

export default BuyerInfo;
