import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import TextInput from 'app/components/TextInput';
import Checkbox from 'app/components/Checkbox';
import ToggleButton from 'app/components/ToggleButton';

import { OrderButton, ToggleWrapper, InputsWrapper } from './styles';
import isValid from 'utils/isValid';
const EMAIL_REGEXP = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/g;

const Form = ({ createOrder }) => {
  const { t } = useTranslation();

  const [personData, setPersonData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    adress: '',
    note: '',
    ppAgree: false,
  });
  const [companyData, setCompanyData] = useState({
    name: '',
    vatNumber: '',
    email: '',
    phone: '',
    adress: '',
    note: '',
    ppAgree: false,
  });

  const [personErrors, setPersonErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    adress: false,
    ppAgree: false,
  });
  const [companyErrors, setCompanyErrors] = useState({
    name: false,
    vatNumber: false,
    email: false,
    phone: false,
    adress: false,
    ppAgree: false,
  });

  const [isCompany, setIsCompany] = useState(false);

  const validatePerson = () => {
    const validation = {
      firstName: false,
      lastName: false,
      email: false,
      phone: false,
      adress: false,
      ppAgree: false,
    };
    for (const key in personData) {
      if (key !== 'note') {
        if (key === 'email') {
          validation[key] = !EMAIL_REGEXP.test(personData[key]);
        } else if (key === 'ppAgree') {
          validation[key] = !personData[key];
        } else {
          validation[key] = personData[key].length <= 0;
        }
      }
    }
    setPersonErrors(validation);
    return isValid(validation);
  };

  const validateCompany = () => {
    const validation = {
      name: false,
      vatNumber: false,
      email: false,
      phone: false,
      adress: false,
      ppAgree: false,
    };
    for (const key in companyData) {
      if (key !== 'note') {
        if (key === 'email') {
          validation[key] = !EMAIL_REGEXP.test(companyData[key]);
        } else if (key === 'ppAgree') {
          validation[key] = !companyData[key];
        } else {
          validation[key] = companyData[key].length <= 0;
        }
      }
    }
    setCompanyErrors(validation);
    return isValid(validation);
  };

  useEffect(() => {
    setPersonErrors({
      firstName: false,
      lastName: false,
      email: false,
      phone: false,
      adress: false,
      ppAgree: false,
    });
  }, [personData]);

  useEffect(() => {
    setCompanyErrors({
      name: false,
      vatNumber: false,
      email: false,
      phone: false,
      adress: false,
      ppAgree: false,
    });
  }, [companyData]);

  useEffect(() => {
    if (isCompany) {
      setCompanyData({
        name: '',
        vatNumber: '',
        email: '',
        phone: '',
        adress: '',
        note: '',
        ppAgree: false,
      });
    } else {
      setPersonData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        adress: '',
        note: '',
        ppAgree: false,
      });
    }
  }, [isCompany]);

  const orderButtonClick = () => {
    if (isCompany) {
      if (validateCompany()) {
        createOrder(companyData);
      }
    } else {
      if (validatePerson()) {
        createOrder(personData);
      }
    }
  };

  return (
    <>
      <ToggleWrapper>
        <ToggleButton
          text={t('form.company')}
          checked={isCompany}
          setChecked={setIsCompany}
          secondaryText={t('form.private_person')}
        />
      </ToggleWrapper>
      {isCompany ? (
        <>
          <TextInput
            placeholder={t('form.company_name')}
            stateKey="name"
            setState={setCompanyData}
            state={companyData.name}
            error={companyErrors.name}
          />
          <TextInput
            placeholder={t('form.vat')}
            stateKey="vatNumber"
            setState={setCompanyData}
            state={companyData.vatNumber}
            error={companyErrors.vatNumber}
          />
        </>
      ) : (
        <InputsWrapper>
          <TextInput
            placeholder={t('form.first_name')}
            width="48%"
            stateKey="firstName"
            setState={setPersonData}
            state={personData.firstName}
            error={personErrors.firstName}
          />
          <TextInput
            placeholder={t('form.last_name')}
            width="48%"
            stateKey="lastName"
            setState={setPersonData}
            state={personData.lastName}
            error={personErrors.lastName}
          />
        </InputsWrapper>
      )}
      <TextInput
        placeholder={t('form.email')}
        type="email"
        stateKey="email"
        setState={isCompany ? setCompanyData : setPersonData}
        state={isCompany ? companyData.email : personData.email}
        error={isCompany ? companyErrors.email : personErrors.email}
      />
      <TextInput
        placeholder={t('form.phone')}
        type="phone"
        stateKey="phone"
        setState={isCompany ? setCompanyData : setPersonData}
        state={isCompany ? companyData.phone : personData.phone}
        error={isCompany ? companyErrors.phone : personErrors.phone}
      />
      <TextInput
        placeholder={isCompany ? t('form.legal_address') : t('form.address')}
        stateKey="adress"
        setState={isCompany ? setCompanyData : setPersonData}
        state={isCompany ? companyData.adress : personData.adress}
        error={isCompany ? companyErrors.adress : personErrors.adress}
      />
      <TextInput
        placeholder={t('form.note')}
        type="textarea"
        stateKey="note"
        setState={isCompany ? setCompanyData : setPersonData}
        state={isCompany ? companyData.note : personData.note}
      />
      <Checkbox
        text={t('form.checkbox')}
        link={{
          text: t('form.checkbox_link'),
          href: '/terms-and-conditions',
        }}
        setState={isCompany ? setCompanyData : setPersonData}
        stateKey="ppAgree"
        checked={isCompany ? companyData.ppAgree : personData.ppAgree}
        error={isCompany ? companyErrors.ppAgree : personErrors.ppAgree}
      />

      <OrderButton extended outlined={false} onClick={orderButtonClick}>
        {t('configurator.order_now')}
      </OrderButton>
    </>
  );
};

export default Form;
