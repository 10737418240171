import styled from 'styled-components';
import theme from 'styles/theme';
import { h1, p, h2, h3 } from 'app/components/Typography/styles';

export const PageWrapper = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
`;

export const TextBlock = styled.div`
  width: 100%;
  max-width: 1170px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  & > h1 {
    ${h1};
    margin-bottom: 33px;
  }
  & > h2 {
    ${h2};
  }
  & > h3 {
    ${h3};
  }
  & > p {
    ${p};
    color: ${theme.colors.primary_light};
  }
  & > ul,
  & > ol {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    & > li {
      ${p};
      color: ${theme.colors.primary_light};
    }
  }

  @media (max-width: ${theme.breakpoints.l}) {
    max-width: 600px;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    max-width: initial;
    padding: 0 30px;
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    padding: 0 15px;
  }
`;

export const ClosePageButton = styled.button`
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 23px;
  right: 19px;
  cursor: pointer;
`;

export const ClosePageIco = styled.img`
  width: 24px;
  height: 24px;
`;
