import styled from 'styled-components';
import BlurredUpImage from 'app/components/BlurredUpImage';
import { h3, h2 } from 'app/components/Typography/styles';
import theme from 'styles/theme';

export const PageWrapper = styled.div`
  width: 100%;
  margin-top: 76px;
  display: flex;
  position: relative;
  @media (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
  }
`;

export const ImageBlock = styled.div`
  width: 75%;
  height: calc(100vh - 76px);
  position: relative;
  @media (max-width: ${theme.breakpoints.l}) {
    width: 70%;
  }
  @media (max-width: ${theme.breakpoints.md}) {
    width: 100%;
    height: calc(50vh - 76px);
    position: fixed;
    z-index: 3;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    height: calc(40vh - 76px);
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 75.43%;
  position: relative;
`;

// export const Image = styled.img`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   width: 100%;
//   transform: translate(-50%, -50%);
// `;

export const Image = styled(BlurredUpImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;

export const BlurredProductImage = styled(BlurredUpImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;

export const HomeButton = styled.button`
  position: absolute;
  left: 0;
  bottom: 92px;
  padding: 12px 15px;
  background-color: ${theme.colors.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }
  @media (max-width: ${theme.breakpoints.md}) {
    display: none;
  }
`;

export const RightBar = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: calc(100vh - 76px);
  @media (max-width: ${theme.breakpoints.l}) {
    width: 30%;
  }
  @media (max-width: ${theme.breakpoints.md}) {
    width: 100%;
    max-height: initial;
    margin-bottom: 170px;
    margin-top: calc(50vh - 76px);
  }
  @media (max-width: ${theme.breakpoints.s}) {
    margin-top: calc(40vh - 76px);
  }
`;

export const ScrollArea = styled.div`
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 20px;
  @media (max-width: ${theme.breakpoints.md}) {
    overflow-y: visible;
  }
`;

export const Block = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  &[id='interior'] {
    margin-top: 75px;
  }
`;

export const VariantsBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 63px;
  &:first-of-type {
    margin-top: 0;
  }
`;

export const ProductTitle = styled.h3`
  ${h3};
  font-weight: 400;
`;

export const GroupTitle = styled.h2`
  ${h2};
`;

interface IScrollAreaEnd {
  buttonVisible: boolean;
}

export const ScrollAreaEnd = styled.div<IScrollAreaEnd>`
  width: 100%;
  margin-top: ${props => (props.buttonVisible ? '-180px' : '-40px')};
`;

export const ImageGroup = styled.div`
  width: 100%;
`;
