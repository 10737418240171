import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useIsVisible } from 'react-is-visible';

import ToggleButton from 'app/components/ToggleButton';
import TotalPrice from 'app/components/TotalPrice';
import ConfiguratorHeader from 'app/components/ConfiguratorHeader';
import VariantsInput from 'app/components/VariantsInput';
import ImageBlock from '../ConfiguratorPage/ImageBlock';

import checkedIco from 'assets/icons/checked.svg';
import closeIco from 'assets/icons/close.svg';
import pdf_file from 'assets/icons/pdf_file.svg';

import {
  PageWrapper,
  RightBar,
  ScrollArea,
  Block,
  ProductTitle,
  VariantsBlock,
  GroupTitle,
  ScrollAreaEnd,
  ImageGroup,
} from './styles';

import {
  setBasePrice,
  setConfig,
  recalculatePrice,
} from 'store/actions/module';
import { Api, IModuleApi } from 'utils/api';
import filter, { IFilterModule, filterModules } from 'utils/new_filters';
import getDefaultVariant from 'utils/getDefaultVariant';
import useDebounce from 'hooks/useDebounce';

import { IModules } from 'types/Module';

import moduleFixure from './module-fixure.json';
import orderFixure from './order-fixture.json';
import orderUpdateFixure from './order-update-fixture.json';
import TotalPriceOrderEditing from 'app/components/TotalPriceOrderEditing';
import {
  BlockSubTitle,
  BlockTitle,
  CheckBlock,
  ClosePageButton,
  ClosePageIco,
  DownloadButton,
  ThanksPopup,
} from '../OrderingPage/styles';

interface IActiveSubgroup {
  image: string;
  image_preview: string;
}

const ConfiguratorPage = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const scrollAreaEndRef = useRef<HTMLDivElement>(null);
  const exteriorRef = useRef<HTMLDivElement>(null);
  const interiorRef = useRef<HTMLDivElement>(null);

  const isVisible = useIsVisible(scrollAreaEndRef);
  const buttonVisible = useDebounce(isVisible, 300);

  const [mirror, setMirror] = useState(false);
  const [order, setOrder] = useState<any>();
  const [modules, setModules] = useState<IModules>();
  const [products, setProducts] = useState<IFilterModule>();
  const [activeSubgroup, setActiveSubgroup] = useState<number>();
  const [currency, setCurrency] = useState('');
  const [currencyRate, setCurrencyRate] = useState<string | null>(null);

  const [popupVisible, setPopupVisible] = useState(false);
  const closePopup = () => {
    setPopupVisible(false);
  };

  const loadStandardModuleVariant = moduleVariantId => {
    Api.getModuleVariant(`${moduleVariantId}`)
      .then((module: any) => {
        if (!module) {
          history.push('/404');
        }
        const modules: IModules = {
          standart: module as any,
        };
        setModules(modules);
      })
      .catch(_ => {
        history.push('/404');
      });
  };

  useEffect(() => {
    Api.getModuleOrder(id)
      .then((_order: any) => {
        if (!_order) {
          history.push('/404');
        }
        setOrder(_order);
        loadStandardModuleVariant(_order.module_variant_id);
      })
      .catch(_ => {
        // debugger;
        // setOrder(orderFixure);
        // loadStandardModuleVariant(orderFixure.module_variant_id);
        history.push('/404');
      });
    Api.getDealer().then(dealer => {
      setCurrency(dealer.currency);
      setCurrencyRate(dealer.currency_rate);
    });
  }, []);

  useEffect(() => {
    if (products) {
      const exteriorConfig = getDefaultVariant(products.exterior);
      const interiorConfig = getDefaultVariant(products.interior);
      const config = { ...exteriorConfig, ...interiorConfig };

      dispatch(setConfig(config));
      dispatch(recalculatePrice());
    }
  }, [products]);

  useEffect(() => {
    if (modules) {
      dispatch(
        setBasePrice(
          mirror ? modules?.mirror?.base_price : modules?.standart.base_price,
        ),
      );
    }

    if (mirror && modules?.mirror) {
      filter(modules.mirror, setProducts);
    }
    if (!mirror && modules?.standart) {
      filter(modules.standart, setProducts);
    }
  }, [modules, mirror]);

  useEffect(() => {
    setActiveSubgroup(undefined);
  }, [mirror]);

  useEffect(() => {
    console.log(products);
  }, [products]);

  const download = () => {
    console.log('download');
  };

  return (
    <>
      <Helmet>
        <title>Configuration Page</title>
      </Helmet>
      <PageWrapper>
        <ImageBlock
          image_groups={
            mirror
              ? modules?.mirror?.image_groups
              : modules?.standart.image_groups
          }
          activeSubgroup={activeSubgroup}
        />
        <RightBar>
          <ConfiguratorHeader
            name={mirror ? modules?.mirror?.name : modules?.standart.name}
            refs={[exteriorRef, interiorRef]}
          />
          <ScrollArea>
            {modules?.mirror && (
              <ToggleButton
                // @ts-ignore
                text={t('configurator.mirror')}
                checked={mirror}
                setChecked={setMirror}
              />
            )}

            {products?.exterior && (
              <Block id="exterior" ref={exteriorRef}>
                {/* @ts-ignore */}
                <GroupTitle>{t('configurator.exterior')}</GroupTitle>
                {products.exterior.map((imageGroup, imageGroupIdx) => (
                  /* @ts-ignore */
                  <ImageGroup
                    key={imageGroup.id}
                    onClick={() => setActiveSubgroup(imageGroup.id)}
                  >
                    {!activeSubgroup &&
                      imageGroupIdx === 0 &&
                      setActiveSubgroup(imageGroup.id)}
                    {imageGroup.subgroups.map(subgroup => {
                      return (
                        subgroup.products.length > 0 && (
                          <VariantsBlock
                            key={`${subgroup.subgroup}_${subgroup.id}`}
                          >
                            <ProductTitle>{subgroup.subgroup}</ProductTitle>
                            {subgroup.products.map(product => (
                              <VariantsInput
                                name={product.name}
                                id={product.id}
                                variants={product.variants}
                                image_blend={
                                  product.image_merge_method === 'merge'
                                }
                                imageGroupId={imageGroup.id}
                                key={`${subgroup.subgroup}_${product.id}`}
                                currency={currency}
                                currency_rate={currencyRate}
                              />
                            ))}
                          </VariantsBlock>
                        )
                      );
                    })}
                  </ImageGroup>
                ))}
              </Block>
            )}

            {products?.interior && products.interior.length > 0 && (
              <Block id="interior" ref={interiorRef}>
                <GroupTitle>{t('configurator.interior')}</GroupTitle>
                {products.interior.map(imageGroup => (
                  <ImageGroup
                    key={imageGroup.id}
                    onClick={() => setActiveSubgroup(imageGroup.id)}
                  >
                    {imageGroup.subgroups.map(subgroup => {
                      return (
                        subgroup.products.length > 0 && (
                          <VariantsBlock
                            key={`${subgroup.subgroup}_${subgroup.id}`}
                          >
                            <ProductTitle>{subgroup.subgroup}</ProductTitle>
                            {subgroup.products.map(product => (
                              <VariantsInput
                                name={product.name}
                                id={product.id}
                                variants={product.variants}
                                image_blend={
                                  product.image_merge_method === 'merge'
                                }
                                imageGroupId={imageGroup.id}
                                key={`${subgroup.subgroup}_${product.id}`}
                                currency={currency}
                                currency_rate={currencyRate}
                              />
                            ))}
                          </VariantsBlock>
                        )
                      );
                    })}
                  </ImageGroup>
                ))}
              </Block>
            )}

            <ScrollAreaEnd
              ref={scrollAreaEndRef}
              buttonVisible={buttonVisible}
            />
          </ScrollArea>
          <TotalPriceOrderEditing
            buttonVisible={buttonVisible}
            currency={currency}
            currency_rate={currencyRate}
            updateOrderHandler={() => {
              //call update order api
              console.log(modules);
              console.log(activeSubgroup);
              //const order = orderUpdateFixure;
              const { customer, module_variant_id } = order;
              const updatedOrder = {
                customer,
                set_product_variant_ids: [module_variant_id],
                module_variant_id,
              };
              Api.updateModuleOrder(id, { order: updatedOrder }).then(() => {
                setPopupVisible(true);
              });
            }}
          />
        </RightBar>
      </PageWrapper>
      {popupVisible && (
        <ThanksPopup>
          <BlockTitle style={{ margin: 0 }}>Thank you!</BlockTitle>
          <BlockSubTitle style={{ margin: 0 }}>
            Your project is being emailed to you right now. You can also
            download it here:
          </BlockSubTitle>
          <CheckBlock>
            <img src={pdf_file} alt="pdf file" />
            <DownloadButton onClick={download}>Download</DownloadButton>
            <ClosePageButton aria-label="Close page" onClick={closePopup}>
              <ClosePageIco src={closeIco} alt="close" />
            </ClosePageButton>
          </CheckBlock>
        </ThanksPopup>
      )}
    </>
  );
};

export default ConfiguratorPage;
