import styled from 'styled-components';
import Typography, { TypographyProps } from 'app/components/Typography';
import { h1 } from 'app/components/Typography/styles';
import theme from 'styles/theme';
import hexToRGBA from 'utils/hexToRGBA';

export const ConfiguratorHeaderContainer = styled.div`
  width: 100%;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.05);
  padding: 45px 21px 21px 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.colors.white};
  @media (max-width: ${theme.breakpoints.l}) {
    padding-top: 25px;
  }
`;

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  @media (max-width: ${theme.breakpoints.l}) {
    margin-bottom: 0;
  }

  a {
    margin-left: 15px;
  }
`;

interface IToggleButton {
  planImage: string | null;
}

export const PlanToogleButton = styled.div<IToggleButton>`
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;

  & input[type='radio'] {
    display: none;
  }

  & label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    margin-right: 0;
    user-select: none;
    width: 52px;
    height: 52px;
    margin: 0;
    border-radius: 50%;
  }

  & label:before {
    content: '';
    display: inline-block;
    width: 46px;
    height: 46px;
    background-color: #ebeef2;
    border: 3px solid ${theme.colors.white};
    border-radius: 50%;
    padding: 3px;
    position: absolute;
    left: 1px;
    bottom: 2px;
    background-image: url(${props => (props.planImage ? props.planImage : '')});
    background-size: 33px;
    background-repeat: no-repeat;
    background-position: center;
  }

  & input[type='radio']:checked + label {
    border: 2px solid ${theme.colors.success};
  }

  &:not(&:first-child) {
    margin-left: 10px;
  }
`;

export const IconButton = styled.button`
  background: none;
  margin-left: 15px;
`;

export const HeaderIco = styled.img`
  width: 48px;
  height: 48px;
  cursor: pointer;
`;

/* 
  @media (max-width: ${theme.breakpoints.md}) {
    display: none;
  }
*/

export const Title = styled.h1`
  ${h1};
  line-height: 1em;
`;

export const StepsBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${theme.breakpoints.l}) {
    display: none;
  }
  /* @media (max-width: ${theme.breakpoints.s}) {
    display: flex;
  } */
`;

export const Step = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const StepLine = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.primary_light};
`;

interface StepIndexProps extends TypographyProps {
  active?: boolean;
}

export const StepIndex = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props: StepIndexProps) =>
    props.active
      ? theme.colors.primary_dark
      : hexToRGBA(theme.colors.primary, 0.3)};
  border-radius: 50%;
  border: ${(props: StepIndexProps) =>
    props.active ? `1px solid ${theme.colors.primary_dark}` : 'none'};

  transition: color 0.2s, border 0.2s;
`;
