import { FC } from 'react';
import { TypographyStyled } from './styles';

export interface TypographyProps {
  children?: any;
  as?: string;
  fontWeight?: 'bold' | 'normal';
  className?: string;
}

const Typography: FC<TypographyProps> = ({
  children,
  fontWeight,
  as,
  className,
}) => {
  return (
    <TypographyStyled
      fontWeight={fontWeight}
      className={`${className ? className : ''} Typography ${as ? as : ''}`}
    >
      {children}
    </TypographyStyled>
  );
};

export default Typography;
