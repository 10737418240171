const theme = {
  colors: {
    primary: '#445C88',
    primary_dark: '#293854',
    primary_light: '#73839C',
    success: '#78E991',
    success_light: '#C5EBBE',
    white: '#FFFFFF',
    error: '#ED6666',
    dark: '#15284B',
    active: '#B5D3FF',
  },
  breakpoints: {
    xxs: '375px',
    xs: '425px',
    s: '600px',
    md: '960px',
    l: '1200px',
    xl: '1400px',
    xxl: '1920px',
  },
  fontFamily: {
    primary: 'Work Sans, sans-serif',
    inter: 'Inter, sans-serif',
    source_sans_pro: 'Source Sans Pro, sans-serif',
    roboto: 'Roboto, sans-serif',
  },

  fonts: {
    h1_l: '36px', // min 1200px
    h1_md: '32px', // min 960px
    h1: '28px', // min 0

    h2_l: '24px', // min 1200px
    h2_md: '22px', // min 960px
    h2: '20px', // min 0

    h3_l: '20px', // min 1200px
    h3_md: '18px', // min 960px
    h3: '18px', // min 0

    body_l: '16px', // min 1200px
    body_md: '14px', // min 960px
    body: '14px', // min 0

    caption_l: '13px', // min 1200px
    caption_md: '12px', // min 960px
    caption: '11px', // min 0
  },
  letterSpacing: {
    h1_l: '-0.01em', // min 1200px
    h1_md: '-0.01em', // min 960px
    h1: '-0.01em', // min 0

    h2_l: '-0.02em', // min 1200px
    h2_md: '-0.02em', // min 960px
    h2: '-0.02em', // min 0

    h3_l: '-0.02em', // min 1200px
    h3_md: '-0.02em', // min 960px
    h3: '-0.02em', // min 0

    body_l: '-0.02em', // min 1200px
    body_md: '-0.02em', // min 960px
    body: '-0.02em', // min 0

    caption_l: '-0.02em', // min 1200px
    caption_md: '-0.02em', // min 960px
    caption: '-0.02em', // min 0
  },
};

export default theme;
