import styled from 'styled-components';

interface IStyledImage {
  blur: boolean;
  image_blend?: boolean;
}

export const StyledImage = styled.img<IStyledImage>`
  filter: ${props => (props.blur ? 'blur(20px)' : 'none')};
  transition: filter 0.3s ease-out;
  mix-blend-mode: ${props => (props.image_blend ? 'multiply' : 'normal')};
`;
