import styled, { css } from 'styled-components';
import { TypographyProps } from './index';
import theme from 'styles/theme';

export const fontWeight = css<TypographyProps>`
  font-weight: ${props => props.fontWeight === 'bold' && 700};
  font-weight: ${props => props.fontWeight === 'normal' && 400};
`;

export const h1 = css`
  font-size: ${theme.fonts.h1};
  letter-spacing: ${theme.letterSpacing.h1};
  font-family: ${theme.fontFamily.primary};
  font-weight: 700;

  @media (min-width: ${theme.breakpoints.md}) {
    font-size: ${theme.fonts.h1_md};
    letter-spacing: ${theme.letterSpacing.h1_md};
  }

  @media (min-width: ${theme.breakpoints.l}) {
    font-size: ${theme.fonts.h1_l};
    letter-spacing: ${theme.letterSpacing.h1_l};
  }

  ${fontWeight};
`;

export const h2 = css`
  font-size: ${theme.fonts.h2};
  letter-spacing: ${theme.letterSpacing.h2};
  font-family: ${theme.fontFamily.primary};
  font-weight: 700;

  @media (min-width: ${theme.breakpoints.md}) {
    font-size: ${theme.fonts.h2_md};
    letter-spacing: ${theme.letterSpacing.h2_md};
  }

  @media (min-width: ${theme.breakpoints.l}) {
    font-size: ${theme.fonts.h2_l};
    letter-spacing: ${theme.letterSpacing.h2_l};
  }

  ${fontWeight};
`;

export const h3 = css`
  font-size: ${theme.fonts.h3};
  letter-spacing: ${theme.letterSpacing.h3};
  font-family: ${theme.fontFamily.primary};
  font-weight: 700;

  @media (min-width: ${theme.breakpoints.md}) {
    font-size: ${theme.fonts.h3_md};
    letter-spacing: ${theme.letterSpacing.h3_md};
  }

  @media (min-width: ${theme.breakpoints.l}) {
    font-size: ${theme.fonts.h3_l};
    letter-spacing: ${theme.letterSpacing.h3_l};
  }

  ${fontWeight};
`;

export const p = css`
  font-size: ${theme.fonts.body};
  letter-spacing: ${theme.letterSpacing.body};
  font-family: ${theme.fontFamily.primary};
  font-weight: 400;

  @media (min-width: ${theme.breakpoints.md}) {
    font-size: ${theme.fonts.body_md};
    letter-spacing: ${theme.letterSpacing.body_md};
  }

  @media (min-width: ${theme.breakpoints.l}) {
    font-size: ${theme.fonts.body_l};
    letter-spacing: ${theme.letterSpacing.body_l};
  }

  ${fontWeight};
`;

export const caption = css`
  font-size: ${theme.fonts.caption};
  letter-spacing: ${theme.letterSpacing.caption};
  font-family: ${theme.fontFamily.primary};
  font-weight: 400;

  @media (min-width: ${theme.breakpoints.md}) {
    font-size: ${theme.fonts.caption_md};
    letter-spacing: ${theme.letterSpacing.caption_md};
  }

  @media (min-width: ${theme.breakpoints.l}) {
    font-size: ${theme.fonts.caption_l};
    letter-spacing: ${theme.letterSpacing.caption_l};
  }

  ${fontWeight};
`;

export const TypographyStyled = styled.p`
  ${p};
  ${fontWeight};

  &.caption {
    ${caption}
  }

  &.h1 {
    ${h1};
  }

  &.h2 {
    ${h2};
  }

  &.h3 {
    ${h3};
  }
`;
