import { FC, useState, RefObject, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsVisible } from 'react-is-visible';

import Typography from 'app/components/Typography';
import Modal from 'app/components/Modal';

import {
  ConfiguratorHeaderContainer,
  TitleBlock,
  HeaderIco,
  StepsBlock,
  StepLine,
  Step,
  StepIndex,
  Title,
  IconButton,
} from './styles';

import planIco from 'assets/icons/plan.svg';
import virtualTourIco from 'assets/icons/virtual-tour.svg';

interface ConfiguratorHeaderProps {
  name?: string;
  planImage?: string;
  virtualTourUrl?: string;
  refs: RefObject<HTMLDivElement>[];
  setActiveSubgroup?: (id: number) => void;
  // exteriorDefaultImageGroupId?: number;
  // interiorDefaultImageGroupId?: number;
}

const ConfiguratorHeader: FC<ConfiguratorHeaderProps> = ({
  name,
  planImage,
  virtualTourUrl,
  refs,
  setActiveSubgroup,
  // exteriorDefaultImageGroupId,
  // interiorDefaultImageGroupId,
}) => {
  const { t } = useTranslation();

  const exteriorVisible = useIsVisible(refs[0]);
  const interiorVisible = useIsVisible(refs[1]);

  /* @ts-ignore */
  const steps = [t('configurator.exterior'), t('configurator.interior')];
  const [activeStep, setStep] = useState(0);

  const [activeVirtualTourModal, setActiveVirtualTourModal] = useState(false);

  useEffect(() => {
    if (interiorVisible && !exteriorVisible) {
      setStep(1);
    } else if (!interiorVisible && exteriorVisible) {
      setStep(0);
    }
  }, [exteriorVisible, interiorVisible]);

  useEffect(() => {
    if (interiorVisible && !exteriorVisible) {
      setStep(1);
    } else if (!interiorVisible && exteriorVisible) {
      setStep(0);
    }
  }, [exteriorVisible, interiorVisible]);

  // useEffect(() => {
  //   setActiveSubgroup &&
  //     setActiveSubgroup(
  //       (activeStep === 0
  //         ? exteriorDefaultImageGroupId
  //         : interiorDefaultImageGroupId) || 0,
  //     );
  // }, [
  //   activeStep,
  //   setActiveSubgroup,
  //   exteriorDefaultImageGroupId,
  //   interiorDefaultImageGroupId,
  // ]);

  const stepSwitch = i => {
    setStep(i);
    refs[i]?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <ConfiguratorHeaderContainer>
      <TitleBlock>
        <Title as="h1">{name}</Title>
        <a href={planImage} target="_blank" rel="noreferrer">
          <HeaderIco alt="Trulli" src={planIco} />
        </a>
        {virtualTourUrl && (
          <IconButton onClick={() => setActiveVirtualTourModal(true)}>
            <HeaderIco alt="Trulli" src={virtualTourIco} />
          </IconButton>
        )}
      </TitleBlock>
      <StepsBlock>
        {steps.map((step, i) => (
          <Fragment key={i}>
            {i > 0 && <StepLine />}
            <Step onClick={() => stepSwitch(i)}>
              <StepIndex active={i === activeStep}>{i + 1}</StepIndex>
              <Typography>{step}</Typography>
            </Step>
          </Fragment>
        ))}
      </StepsBlock>
      <Modal
        active={activeVirtualTourModal}
        hideModal={() => setActiveVirtualTourModal(false)}
        title={`${name} - Virtual tour`}
      >
        <iframe
          src={virtualTourUrl}
          width={'100%'}
          height={'700px'}
          title="Virual tour"
        ></iframe>
      </Modal>
    </ConfiguratorHeaderContainer>
  );
};

export default ConfiguratorHeader;
