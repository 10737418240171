import styled from 'styled-components';
import theme from 'styles/theme';

export const StyledInput = styled.input`
  width: 100%;
  height: 50px;
  margin-top: 23px;
  padding: 14px 18px;
  border: 1px solid ${theme.colors.primary_light};
  border-radius: 4px;

  font-style: 16px;
  font-family: ${theme.fontFamily.inter};
  color: ${theme.colors.primary};

  transition: border-color 0.3s;

  &::placeholder {
    color: ${theme.colors.primary_light};
  }
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  min-height: 104px;
  margin-top: 23px;
  padding: 14px 18px;
  border: 1px solid ${theme.colors.primary_light};
  border-radius: 4px;

  font-style: 16px;
  font-family: ${theme.fontFamily.inter};
  color: ${theme.colors.primary};

  &::placeholder {
    color: ${theme.colors.primary_light};
  }
`;
