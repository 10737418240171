import axios from 'axios';

import {
  IModule,
  IModulePart,
  IModuleVariant,
  IModuleSubgroup,
  IModuleImageGroup,
} from 'types/Module';
import { IDealer } from 'types/Dealer';
import { IOrderBody, IOrderRes } from 'types/Order';

const baseUrl =
  process.env.NODE_ENV === 'development'
    ? window._env_.REACT_APP_API_ENDPOINT
    : '';

export const Api = {
  async getModules(): Promise<IModule[]> {
    const { data } = await axios.get(`${baseUrl}/api/module_groups`);

    return data;
  },
  async getModule(id: string): Promise<IModuleApi> {
    const { data } = await axios.get(`${baseUrl}/api/module_variants/${id}`);
    return data;
  },
  async getModuleVariants(id: string): Promise<IModuleApi[]> {
    const { data } = await axios.get(
      `${baseUrl}/api/module_variants?group_id=${id}`,
    );
    return data;
  },
  async getModuleVariant(id: string): Promise<IModuleApi[]> {
    const { data } = await axios.get(
      `${window._env_.REACT_APP_API_ENDPOINT}/api/module_variants/${id}`,
    );
    return data;
  },
  async getDealer(): Promise<IDealer> {
    const { data } = await axios.get(
      `${window._env_.REACT_APP_API_ENDPOINT}/api/dealers`,
    );
    return data;
  },

  async createOrder(body: IOrderBody): Promise<IOrderRes> {
    const { data } = await axios.post(`${baseUrl}/api/orders`, {
      order: body,
    });
    return data;
  },
  async getModuleOrder(id: string): Promise<IModuleApi[]> {
    const { data } = await axios.get(
      `${window._env_.REACT_APP_API_ENDPOINT}/api/orders/${id}/edit`,
    );
    return data;
  },
  async updateModuleOrder(id: string, params): Promise<IModuleApi[]> {
    const { data } = await axios.put(
      `${window._env_.REACT_APP_API_ENDPOINT}/api/orders/${id}`,
      params,
    );
    return data;
  },

  async downloadPdf(uuid: string): Promise<void> {
    axios
      .get(`${baseUrl}/api/orders/${uuid}/order_pdf`, {
        responseType: 'blob',
      })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'order.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  },
};

export interface IModuleApi extends IModuleVariant {
  products: IModulePart[];
  subgroups: IModuleSubgroup[];
  image_groups: IModuleImageGroup[];
}
