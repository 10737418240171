import styled from 'styled-components';
import theme from 'styles/theme';

export const CookieConsentWrapper = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100vw;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #323231;
  color: #e7e7e7;
`;

export const CookieConsentMessage = styled.div`
  font-size: 14px;
  line-height: 16px;
  margin-top: 10.5px;
  margin-bottom: 13.5px;
  margin-left: 24px;
  a {
    color: #e7e7e7;
    background-color: #323231;
  }
`;

export const CookieConsentContinueBtn = styled.button`
  font-weight: 600;
  padding: 3px 12px;
  margin: 0 24px;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #e7e7e7;
  color: inherit;
  transition: ease-in-out 0.1s;

  &:hover {
    background-color: #e7e7e7;
    color: #323231;
    transition: ease-in-out 0.1s;
  }
`;
