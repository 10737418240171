import styled from 'styled-components';
import Typography from 'app/components/Typography';
import Button from 'app/components/Button';
import { h3 } from 'app/components/Typography/styles';
import theme from 'styles/theme';

export const PriceBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 49px 20px 49px;
  box-shadow: 0px -5px 15px 0px rgba(0, 0, 0, 0.05);
  background-color: ${theme.colors.white};
  @media (max-width: ${theme.breakpoints.md}) {
    position: fixed;
    bottom: 0;
    left: 0;
  }
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${theme.breakpoints.md}) {
    flex-direction: row;
  }
`;

export const Title = styled(Typography)`
  ${h3};
  font-weight: 400;
  color: ${theme.colors.primary_light};
  margin-bottom: 2px;
  @media (max-width: ${theme.breakpoints.md}) {
    margin-right: 10px;
  }
`;

export const Price = styled(Typography)`
  ${h3};
  color: ${theme.colors.dark};
`;

export const OrderButton = styled(Button)`
  margin-top: 22px;
  color: ${theme.colors.primary};
`;
