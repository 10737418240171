import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GlobalStyle } from 'styles/global-styles';

import { HomePage } from './pages/HomePage/Loadable';
import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import ConfiguratorPage from './pages/ConfiguratorPage';
import OrderingPage from './pages/OrderingPage';
import OrderEditingPage from './pages/OrderEditingPage';
import AboutUsPage from './pages/AboutUsPage';
import ContactsPage from './pages/ContactsPage';
import TermsAndConditions from './pages/TermsAndConditions';

import Header from 'app/components/Header';
import { CookieConsent } from './components';
import loadLocale from 'locales/loadLocale';

window._env_ = {
  REACT_APP_API_ENDPOINT:
    process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000',
};

export function App() {
  const { i18n } = useTranslation();

  React.useEffect(() => {
    initLocale();
  }, []);

  const initLocale = async () => {
    await loadLocale();
  };

  return (
    <>
      {/* @ts-ignore */}
      <BrowserRouter>
        {/* @ts-ignore */}
        <Helmet
          titleTemplate="%s - React Boilerplate"
          defaultTitle="React Boilerplate"
          htmlAttributes={{ lang: i18n.language }}
        >
          <meta name="description" content="A React Boilerplate application" />
        </Helmet>
        <Header />
        <CookieConsent />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/configurator/:id" component={ConfiguratorPage} />
          <Route exact path="/order" component={OrderingPage} />
          <Route exact path="/order/:id/edit" component={OrderEditingPage} />
          <Route exact path="/about" component={AboutUsPage} />
          <Route exact path="/contacts" component={ContactsPage} />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Route component={NotFoundPage} />
        </Switch>
        <GlobalStyle />
      </BrowserRouter>
    </>
  );
}
