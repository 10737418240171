import './i18n';
import i18next from 'i18next';
import { Api } from 'utils/api';

const loadLocale = async () => {
  const { country_code } = await Api.getDealer();

  if (country_code) {
    i18next.changeLanguage(country_code);
  }
};

export default loadLocale;
