import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { StyledButton } from './styles';

export interface ButtonProps {
  children: string;
  to?: string;
  className?: any;
  onClick?: Function;
  outlined?: boolean;
  color?: string;
  bgColor?: string;
  extended?: boolean;
}

const Button: FC<ButtonProps> = ({
  children,
  to,
  onClick,
  className,
  outlined = true,
  color,
  bgColor,
  extended,
}) => {
  const history = useHistory();

  const handleOnSubmit = () => {
    if (to) {
      history.push(to);
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <StyledButton
      className={className}
      onClick={handleOnSubmit}
      color={color}
      outlined={outlined}
      bgColor={bgColor}
      extended={extended}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
